<template>
  <div class="content-layout-fixer">
    <VueWrapperNarrow>
      <div id="divCookiePolicyWrapper" class="cookie-policy-wrapper">
        <BrandCookies />
      </div>
    </VueWrapperNarrow>
  </div>
</template>
<script>
import BrandCookies from '@/components/brand/BrandCookies/BrandCookies';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';

export default {
  name: 'CookiePolicy',
  components: {
    BrandCookies,
    VueWrapperNarrow,
  },
  data() {
    return {
      showCookie: false,
    };
  },
  mounted() {
    document
      .getElementById('divCookiePolicyWrapper')
      .querySelectorAll('a')
      .forEach(x => {
        x.setAttribute('target', '_blank');
      });
  },
};
</script>
<style lang="scss" scoped>
.cookie-policy-wrapper {
  width: 100%;
  padding-left: palette-space-level('20');
  margin: palette-space-level('60') 0;
  .table-wrapper {
    overflow: auto;
    font-size: small;
    max-width: 100%;
  }
  &-button {
    border-radius: 4px;
    margin-bottom: palette-space-level('20');
  }
  a {
    color: palette-color-level('grey', 40) !important;
  }
  h2 {
    padding: 5px 0;
    font-size: 18px !important;
    font-weight: 500;
  }
  p,
  h2 {
    margin-bottom: 10px;
  }
  table {
    th,
    td {
      border: 1px solid palette-color-level('grey', 20);
      padding: 5px;
    }
  }
}
</style>
