<template>
  <div v-show="cookieTypes">
    <div v-if="cookieTypes">
      <VueWrapperNarrow>
        <div class="cookie-title">
          <VueText sizeLevel="8" weightLevel="3" color="grey-100">Çerezler(Cookies)</VueText>
        </div>
        <VueText class="cookie-title-secondary" sizeLevel="5" weightLevel="3">
          Çerezler veya web işaretçileri nedir?</VueText
        >
        <VueText class="cookie-text" sizeLevel="4" weightLevel="1">
          Çerezler, izleme teknolojilerinin bir örneğidir. Bunlar dijital bir platformu ziyaret
          ettiğinizde bilgisayarınıza yerleşen ve daha sonra aşağıda belirtilen amaçlarla
          bilgisayarınızın kimliğini tespit etme amacıyla kullanılan küçük metin dosyalarıdır.
          Çerezler "birinci taraf çerezleri" adı verilen bir dijital platformun sahibi tarafından
          yerleştirilir. Diğer kişilerce yerleştirilen çerezler "üçüncü taraf çerezleridir". Üçüncü
          taraf çerezleri, üçüncü tarafın dijital platform üzerinde veya onun aracılığıyla (analiz,
          reklam ve video gibi) özellikler ve işlevler sunmasına imkan verir. Üçüncü taraf çerezleri
          yerleştiren taraflar cihazınız dijital platformu ziyaret ettiğinde ve diğer dijital
          platformları ziyaret ettiğinde cihazını tanıyabilir. Bu sitede çerezler (ve benzeri
          teknolojiler) kullanmaktayız. Bu çerezler üç kategoriye ayrılmaktadır ve bu kategoriler
          aşağıda açıklanmıştır. Tarafımızdan gelen bu çerezleri kabul edip etmeyeceğinize karar
          verebilirsiniz.</VueText
        >

        <div class="modal-header-with-actions">
          <VueText sizeLevel="7" weightLevel="3">Çerez Ayarları</VueText>

          <div class="header-buttons" @click.prevent="toggleSelect('all')">
            <VueText sizeLevel="3" weightLevel="3">Tümünü Seç</VueText>

            <VueIcon :iconName="modalIcons.circle.name" v-show="!allSelected" iconColor="#000000" />
            <VueIcon v-show="allSelected" :iconName="modalIcons.tick.name" iconColor="#000000" />
          </div>
        </div>
        <div v-if="!!cookieTypes">
          <div
            class="modal-body-single-item"
            v-for="cookieType in cookieTypes"
            :key="cookieType.type"
          >
            <div class="modal-body-action-row" @click.prevent="toggleSelect(cookieType.key)">
              <VueText sizeLevel="3" weightLevel="3" color="grey-100">{{
                cookieType.name
              }}</VueText>
              <VueIcon
                v-show="!postModel[cookieType.key]"
                :iconName="modalIcons.circle.name"
                iconColor="#000000"
              />
              <VueIcon
                v-show="postModel[cookieType.key]"
                :iconName="modalIcons.tick.name"
                iconColor="#000000"
              />
            </div>
            <VueText sizeLevel="4" weightLevel="1">{{ cookieType.description }}</VueText>
            <VueText sizeLevel="5" weightLevel="3" class="cookies-used"
              >Kullanılan Çerezler</VueText
            >
            <details
              v-for="detail in cookieType.cookies"
              :key="detail.name"
              class="collapsible-cookies"
            >
              <summary class="summary">{{ detail.name }} </summary>
              <VueText sizeLevel="4" weightLevel="1">
                {{ detail.description }}
              </VueText>
            </details>
          </div>
        </div>
        <div class="submit-wrapper">
          <BrandButton
            class="cookie-submit"
            :size="sizes.xxLarge"
            :contentAlignment="constants.flexAlignment.center"
            type="submit"
            :padding="{ vertical: 15, horizontal: 0 }"
            @click="handleSubmit"
            >GÜNCELLE</BrandButton
          >
        </div>
      </VueWrapperNarrow>
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import { Common, User } from '@/services/Api/index';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import StorageProps from '@/mixins/storageProps.js';
import { convertToObjectBy } from '@/mixins/arrayUtils';
import StorageHelper from '@/utils/storageHelper';
export default {
  name: 'BrandCookies',
  components: { VueText, VueIcon, BrandButton, VueWrapperNarrow },
  mixins: [StorageProps],
  props: {},
  data() {
    return {
      renderKey: 1,
      allSelected: false,
      cookieTypes: null,
      postModel: {
        functionalCookie: true,
        trackingCookie: true,
        performanceCookie: true,
      },
    };
  },
  mounted() {
    Common.getCookieDetails().then(res => {
      if (res.data.Data.cookieTypes) {
        this.cookieTypes = res.data.Data.cookieTypes.map(obj => {
          const item = { ...obj, key: '' };
          item.name =
            item.type == 1
              ? 'İşlemsel Çerezler'
              : item.type == 2
              ? 'Takip Çerezleri'
              : 'Performans Çerezleri';
          item.key =
            item.type == 1
              ? 'functionalCookie'
              : item.type == 2
              ? 'trackingCookie'
              : 'performanceCookie';
          return item;
        });
      }
    });
    if (this.getCookieSettings) {
      let userCookieSettings = convertToObjectBy(this.getCookieSettings.cookieTypes, 'type');
      this.postModel.trackingCookie = userCookieSettings[2].enable;
      this.postModel.performanceCookie = userCookieSettings[3].enable;
    }
  },
  watch: {
    allSelected: {
      handler(value) {
        this.postModel.trackingCookie = value;
        this.postModel.performanceCookie = value;
      },
    },
  },
  methods: {
    // Cookie is reading.
    getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
    // New cookie is writing.
    setCookie(cname, cvalue, exdays) {
      let d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },
    handleSubmit() {
      User.setUserCookieSettings(this.postModel).then(res => {
        this.$store.dispatch('app/setCookieSettings', res.data.Data.cookieSettings);
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_COOKIE_SETTINGS }).set(
          res.data.Data.cookieSettings,
        );
      });
      if (!this.postModel.performanceCookie) {
        let performanceCookies = this.getCookieSettings.cookieTypes[2].cookies;
        for (let index = 0; index < performanceCookies.length; index++) {
          this.setCookie(performanceCookies[index], this.getCookie(performanceCookies[index]), -1);
        }
      }
      if (!this.postModel.trackingCookie) {
        let trackingCookies = this.getCookieSettings.cookieTypes[1].cookies;
        for (let index = 0; index < trackingCookies.length; index++) {
          this.setCookie(trackingCookies[index], this.getCookie(trackingCookies[index]), -1);
        }
      }
      this.$emit('update:show', false);
    },
    toggleSelect(e) {
      if (e === 'all') {
        this.allSelected = !this.allSelected;
      } else if (e !== 'functionalCookie') {
        this.postModel[e] = !this.postModel[e];
      }
    },
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    modalIcons() {
      return {
        circle: ICON_VARIABLES.circle,
        tick: ICON_VARIABLES.rememberMe,
        caret: ICON_VARIABLES.chevronDownAlt,
        cross: ICON_VARIABLES.cross,
      };
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/styles/_animations.scss';

.cookie-title {
  margin-bottom: palette-space-level('20');
}
.cookie-title-secondary {
  margin-bottom: palette-space-level('10');
}
.cookie-text {
  margin-bottom: palette-space-level('20');
}
.modal-header-with-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: palette-space-level('25') palette-space-level('20');
  background: palette-color('grey-10');
  .header-buttons {
    display: flex;
    align-items: center;
    p {
      margin-right: palette-space-level('15');
    }
  }
}
.modal-body-single-item {
  display: flex;
  flex-direction: column;
  margin: palette-space-level('30') 0;
  &:first-child {
    margin-top: palette-space-level('20');
  }
  .collapsible-cookies {
    summary {
      padding: palette-space-level('5');
    }
    p {
      padding: palette-space-level('5') palette-space-level('5') palette-space-level('5')
        palette-space-level('10');
    }
  }
  .modal-body-action-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: palette-space-level('15') 0;
    padding-right: palette-space-level('20');
    margin-bottom: palette-space-level('15');
    border-bottom: 1px solid rgba(palette-color('grey-20'), 0.6);
    p {
      font-size: palette-font-size-level('7');
    }
  }
}
/deep/ .cookies-used {
  margin: palette-space-level('20') 0 !important;
}
.summary {
  font-size: palette-font-size('font-size-4');
  font-weight: palette-font-weight('font-weight-1');
  color: palette-color('grey-30');
}
</style>
